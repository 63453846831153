<template>
    <div class="app-container">
        <el-row>
            <el-col :span="2">
                <el-button type="primary" icon="el-icon-plus" size="small" @click="addDeviceIcon">
                    {{$t('base.device.addIcon')}}
                </el-button>
            </el-col>
            <el-col :span="6" :offset="16">
                <div class="filter-container" align="right">
                    <el-select @change="handleFilter" v-model="listQuery.deviceType"
                               @clear="clearEmpty('deviceType')"
                               filterable clearable
                               size="small" style="width: 200px;" class="filter-item"
                               :placeholder="$t('base.device.type')">
                        <el-option
                                v-for="item in deviceTypeList"
                                :key="item.itemCode"
                                :value="item.itemCode"
                                :label="item.itemName">
                        </el-option>
                    </el-select>
                    <el-button size="small" class="filter-item" type="primary" icon="el-icon-search" :loading="listLoading" @click="handleFilter">
                        {{$t('commons.search') }}
                    </el-button>
                </div>
            </el-col>
        </el-row>
        <el-table
                v-loading="listLoading"
                :data="list"
                element-loading-text="Loading"
                border
                fit
                highlight-current-row
                stripe>
            <el-table-column :label="$t('base.device.type')" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.deviceTypeName }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('base.device.startIcon')" align="center">
                <template slot-scope="scope">
                    <img :src="getDeviceIcon(scope.row.startPhoto)" class="col-device-icon" :onerror="defaultDeviceIcon"/>
                </template>
            </el-table-column>
            <el-table-column :label="$t('base.device.stopIcon')" align="center">
                <template slot-scope="scope">
                    <img :src="getDeviceIcon(scope.row.stopPhoto)" class="col-device-icon" :onerror="defaultDeviceIcon"/>
                </template>
            </el-table-column>
            <el-table-column prop="operation" :label="$t('commons.actions')" align="center" width="160">
                <template slot-scope="scope">
                    <el-tooltip :content="$t('commons.edit')" placement="bottom" :open-delay="openDelay">
                        <el-button
                                size="mini"
                                icon="el-icon-edit"
                                type="primary"
                                circle
                                @click="updateDeviceIcon(scope.row)">
                        </el-button>
                    </el-tooltip>

                    <el-tooltip :content="$t('commons.delete')" placement="bottom" :open-delay="openDelay">
                        <el-button
                                size="mini"
                                icon="el-icon-delete"
                                type="danger"
                                circle
                                @click="deleteDeviceIcon(scope.row.deviceType)">
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <pagination v-show="total>0" :total="total" :page.sync="listQuery.current" :limit.sync="listQuery.rowCount"
                    @pagination="getDeviceIconPage"/>

        <!-- 添加设备图标对话框 -->
        <el-dialog :visible.sync="addDialog" class="device-icon-dialog"
                   :before-close="cancelDeviceIconSave" width="576px" :close-on-click-modal="false">
            <template slot="title">
                <title-icon/>{{ isCreate ? $t('base.device.addIcon') : $t('base.device.editIcon') }}
            </template>
            <el-form :model="form" ref="deviceIconForm" label-width="80px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item :label="$t('base.device.type')" prop="deviceType">
                            <el-select v-model="form.deviceType" filterable clearable class="device-type" :disabled="!isCreate">
                                <el-option
                                        v-for="item in deviceTypeList"
                                        :key="item.itemCode"
                                        :value="item.itemCode"
                                        :label="item.itemName">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item :label="$t('base.device.startIcon')" prop="startPhoto">
                            <el-upload
                                    class="device-icon-upload"
                                    :on-success="uploadStartDeviceSuccess"
                                    :on-error = "uploadFail"
                                    :show-file-list="false"
                                    :with-credentials="true"
                                    drag
                                    :action="uploadPath">
                                <img :src="getDeviceIcon(form.startPhoto)" class="device-icon" :onerror="defaultDeviceIcon"/>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('base.device.stopIcon')" prop="stopPhoto">
                            <el-upload
                                    class="device-icon-upload"
                                    :on-success="uploadStopDeviceSuccess"
                                    :on-error = "uploadFail"
                                    :show-file-list="false"
                                    :with-credentials="true"
                                    drag
                                    :action="uploadPath">
                                <img :src="getDeviceIcon(form.stopPhoto)" class="device-icon" :onerror="defaultDeviceIcon"/>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button @click="cancelDeviceIconSave">{{$t('commons.cancel') }}</el-button>
                <el-button type="primary" @click="handleDeviceIconSave"
                           v-loading.fullscreen.lock="createLoading">{{$t('commons.save') }}
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        getDeviceIconPage,
        getLoopUpItem,
        createDeviceIcon,
        updateDeviceIcon,
        deleteDeviceIcon
    } from '@/api/business/base/tenant/device'
    import Pagination from '../../../../../components/Pagination'
    import { envInfo } from '@/constants/envInfo'

    export default {
        name: 'DeviceIcon',
        components: {Pagination},
        data() {
           return{
               addDialog: false,
               createLoading: false,
               deviceTypeList:[],
               list: null,
               total: 0,
               listLoading: true,
               listQuery: {
                   current: 1,
                   rowCount: 10,
                   deviceType: null,
               },
               form:{
                   deviceType : null,
                   startPhoto : null,
                   stopPhoto : null
               },
               openDelay:1000,
               isCreate: true,
               // 默认设备图标
               defaultDeviceIcon: 'this.src="' + require("@/assets/images/monitor.png") + '"',
               uploadPath:envInfo.bgApp.archivePath+'/param/archive/upload?ulType=DefaultUpload',
           }
        },
        created() {
            this.getDeviceIconPage();
            this.getDeviceTypeList();
        },
        methods: {
            getDeviceTypeList(){
                getLoopUpItem({classifyCode: 'DEVICE_TYPE'}).then((row)=> {
                    this.deviceTypeList = row;
                })
            },
            getDeviceIconPage() {
                this.listLoading = true
                getDeviceIconPage(this.listQuery).then(response => {
                    this.list = response.rows;
                    this.total = response.total;
                    this.listLoading = false;
                })
            },
            handleFilter() {
                this.listQuery.current = 1
                this.getDeviceIconPage();
            },
            cancelDeviceIconSave() {
                this.addDialog = false;
            },
            clearForm(){
                this.form.deviceType = null;
                this.form.startPhoto = null;
                this.form.stopPhoto = null;
            },
            addDeviceIcon(){
                this.isCreate = true;
                this.clearForm();
                this.addDialog = true;
            },
            updateDeviceIcon(row){
                this.isCreate = false;
                this.form = Object.assign({}, row);
                this.addDialog = true;
            },
            deleteDeviceIcon(deviceType){
                this.$confirm(this.$t('message.deleteConfirm'), this.$t('commons.warning'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    deleteDeviceIcon({"deviceType":deviceType}).then(()=>{
                        // 成功提示
                        this.$message({
                            message: this.$t('message.deleteSuccess'),
                            type: 'success'
                        });
                        // 刷新列表
                        this.handleFilter();
                    });
                }).catch(error => {
                    console.log(`未删除，原因 => ${error}`)
                });
            },
            //保存设备图标
            handleDeviceIconSave() {
                if(this.isCreate){
                    createDeviceIcon(this.form).then(() =>{
                        // 成功后重置form,并关闭弹框和遮罩层
                        this.addDialog = false;
                        this.createLoading = false;
                        // 成功提示
                        this.$message({
                            message: this.$t('message.saveSuccess'),
                            type: 'success'
                        });
                        // 刷新列表
                        this.handleFilter();
                    })
                }else{
                    updateDeviceIcon(this.form).then(() =>{
                        // 成功后重置form,并关闭弹框和遮罩层
                        this.addDialog = false;
                        this.createLoading = false;
                        // 成功提示
                        this.$message({
                            message: this.$t('message.saveSuccess'),
                            type: 'success'
                        });
                        // 刷新列表
                        this.handleFilter();
                    })
                }

            },
            getDeviceIcon(fileId){
                if(fileId) {
                    return envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + fileId;
                }
                return "";
            },
            uploadStartDeviceSuccess(files) {
                this.form.startPhoto = files[0].fileId;
                this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
            },
            uploadStopDeviceSuccess(files) {
                this.form.stopPhoto = files[0].fileId;
                this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
            },
            uploadFail() {
                this.$message({type: 'error', message: this.$t('message.uploadFial')});
            },
            clearEmpty(field) {
                this.listQuery[field] = null
            }
        }
    }
</script>

<style scoped>

    .device-icon-dialog >>> .el-dialog__body {
        padding: 20px 20px 0px;
    }

    .col-device-icon {
        width: 28px;
        height: 28px;
        border-radius: 10px;
    }

    .device-icon-upload >>> .el-upload-dragger{
        width: 167px;
        height: 167px;
        padding: 10px;
    }

    .device-icon {
        width: 100%;
        border-radius: 10px;
    }

    .device-type {
        width: 436px;
    }
</style>
